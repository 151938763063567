registerProcessor(
  'QuantizerProcessor',
  class QuantizerProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'signal',
          defaultValue: 0,
          automationRate: 'k-rate',
        },
      ];
    }

    range: number;
    values: Float32Array;
    position: number = 0;

    constructor(options: AudioWorkletNodeOptions) {
      super(options);
      this.range = options.processorOptions.range;
      this.values = options.processorOptions.values;

      this.port.onmessage = ({ data: [type, value] }) => {
        switch (type) {
          case 'range':
            this.range = value;
            break;
          case 'values':
            this.values = value;
            break;
          default:
            throw new Error('unrecognized message');
        }
      };
    }

    process(
      _: Float32Array[][],
      [[output], [trigger]]: Float32Array[][],
      { signal: [signal] }: { [param: string]: Float32Array },
    ): boolean {
      const { values, range } = this;
      const cycle = Math.floor(signal / range) * range;
      let remainder = signal % range;
      if (remainder < 0) remainder = remainder + range;

      let position = 0;
      let value = 0;
      let difference = Math.abs(remainder - range);
      for (let i = 0, len = values.length; i < len; i++) {
        const currentValue = values[i] % range;
        const currentDifference = Math.abs(remainder - currentValue);
        if (currentDifference < difference) {
          position = i;
          value = currentValue;
          difference = currentDifference;
        }
      }

      output.fill(cycle + value);

      if (position !== this.position) {
        trigger[0] = 1;
        this.position = position;
        this.port.postMessage(['position', position]);
      }

      return true;
    }
  },
);
